<template>
  <div :key="name" class="d-flex flex-row morePropertiesItem">

    <v-tooltip :disabled="!isTitleTrimmed" top>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="mb-n4 morePropertiesItemText  text-truncate pr-1"  >{{title}}</div>
      </template>
      <span  >{{title}}</span>
    </v-tooltip>
    <div class="ml-auto" :style="'width:' + width ">
      <SmartNumber :val="val"></SmartNumber>
    </div>
    <div><v-icon class="ml-0" small @click="onRemoveSelectedProperties(id)">mdi-close</v-icon></div>
  </div>
</template>
<script>
import SmartNumber from "@/components/SmartNumber.vue";

export default {
  name: "MorePropertiesItem",
  components:{SmartNumber},
  
  props:{
    name: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    }, 
    val: {
        type: Number,
        required: true
    }, 
    id: {
        type: Number,
        required:true
    },
    width: {
        type: String,
        default: '68px'
    }
  },
  data:()=>({
    maxTitleLengh:35
  }),
  computed:{
    isTitleTrimmed(){
        return this.title.length > this.maxTitleLengh
    },
  },
  methods:{
    onRemoveSelectedProperties(id){
        this.$emit('onRemove', id)
    }
  }
};
</script>
<style scoped>

.morePropertiesItem{
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  height: 37px;
  width: 100%;
  /* margin-bottom: 2px; */
}

.theme--dark .morePropertiesItem {
  background-color: #272A2F;

}
.morePropertiesItemText{
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

</style>