<template>
    <span :class=" 'number ' + iClass " >
        {{ newVal }}<span v-if="exp"> ×10<sup  >{{ this.exp.exp }}</sup></span>
    </span>
</template>
<script>
import {pruneNumber, toExp, smartPruneNumber} from '@/utils/number'
export default {
    name:'SmartNumber',
    props:{
        val:{
            type:Number,
            required: true
        },
        iClass:{
            type:String,
            default:''
        },
        precision:{
            type:Number,
            default: null
        }
    },
    methods:{
    },
    computed:{

        exp(){
            console.log(this.val)

            if(this.val != 0){
                // const x =  Math.ceil(Math.log10(Math.abs(this.val)))
                // if(Math.abs(x) > 2){
                //     return x - 1
                // } else {
                //     return null
                // }
                const x =  toExp(this.val)
                if(Math.abs(x.exp) > 2){
                    return x
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        newVal(){
            if(this.exp){
                if(this.precision){
                    return pruneNumber(this.exp.base, this.precision)
                }
                else {
                    return smartPruneNumber(this.exp.base)
                }
            } else if(this.precision){
                return pruneNumber(this.val,this.precision)
            }
            return smartPruneNumber(this.val)
        },
    }
}
</script>