<template>
  <div>
    <div class="mt-6 d-flex flex-row" >
      <div class="text-h3 font-weight-bold textTitle">Composition</div>
      <div class="ml-auto" style="width: 90px">
        <v-menu v-model="menu" max-width="85px" :close-on-content-click="true" :nudge-width="200" offset-y class="body-2" >
          <template v-slot:activator="{ on, attrs }">
          <c-btn radius="8px"  small class="compositionBtn" v-on="on" v-bind="attrs"> {{ compositionTypeTitle }} <v-icon>mdi-chevron-down</v-icon> </c-btn>
          </template>
          <v-list >
            <v-list-item dense 
              v-for="(item, index) in compositionTypes"
              :key="index"
              @click="onPropertiesTypeChanged(item.id)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
  
        <!-- <v-select :hide-details="true" class="body-2"  :menu-props="{ bottom: true, offsetY: true, dense:true }" solo flat :items="compositionTypes" v-model="compositionType" item-text="title" item-value="id" @change="onPropertiesTypeChanged()"></v-select> -->
      </div>

    </div>
    <div class="d-flex flex-row">
      <apexchart class="mb-n5 mt-n5" ref="myChart" :height="chartHeight" :options="chartOptions" :series="series"></apexchart>
      <div class="d-flex flex-column justify-space-around mt-2" :style="'height:calc('+chartHeight + ' - 45px)'">
          <div class="number dataLabel"  v-for="(item,index) in series[0].data" :key="index">{{ item }}</div>
      </div>
    </div>
    <div class="mt-2 d-flex flex-row">
      <div class="mt-3 text-h3 font-weight-bold textTitle mb-1">More Properties</div>
    </div>
    <MoreProperties ref="moreProperties" :normalDatasetFields="normalDatasetFields" :tableData="tableData" @onChanged="onMorePropertiesChanged"></MoreProperties>

  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { getFilteredAggregations } from "@/api/user/dataset";
import CBtn from "@/components/Vuetify/CBtn.vue";
import { pruneNumber } from "@/utils/number";
import MoreProperties from "@/components/Mat/MoreProperties.vue";
// import Number from '@/components/Number.vue'

export default {
  name: "CompositionChart",
  components: {
    apexchart: VueApexCharts,
    CBtn,
    MoreProperties
    // Number
  },
  props: {
    packageId: {
      type: Number,
      default: null,
    },
    datasetId: {
      type: Number,
      default: null,
    },
    normalDatasetFields: {
      type: Array,
      default: () => [],
    },
    normalDatasetCompositionFields: {
      type: Array,
      default: () => [],
    },
  },
  computed:{
    morePropertiesHeight(){      // alert( window.innerHeight -  (el.y+el.height))

      const el=document.getElementById('morePropertiesHeaderId')
      var diff = 170
      if(el){
        const r = el.getBoundingClientRect()
        diff = window.innerHeight -  (r.y+r.height) - 60    
      }
      return Math.min(this.selectedProperties.length*45,diff)
    },
    compositionTypeTitle(){
      const idx = this.compositionTypes.findIndex(item=>item.id == this.compositionType)
      return this.compositionTypes[idx].title
    }
  },
  data: () => ({
    menu: null,
    chartHeight:'340px',
    compositionTypes: [
      {
        id: "COMPOSITION_WT",
        title: "wt%",
      },
      {
        id: "COMPOSITION_AT",
        title: "at%",
      },
    ],
    compositionType: "COMPOSITION_AT",
    selectedProperties: [],
    loading: false,
    tableData: [],
    filters: [],
    series: [
      {
        data: [],
      },
    ],
    chartOptions: {
      colors: ["#0393A8"],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ['#0393A8'], // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.1,
          stops: [0,  100],
          colorStops: []
        }
      },
      series: [],
      chart: {
        type: "bar",
        background: "transparent",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        xaxis: {
          lines: {
            show: true,
          },
        },
        borderColor: '#90A4AE40',
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      plotOptions: {
        bar: {
          barHeight: "90%",
          borderRadius: 1,
          horizontal: true,
          // dataLabels: {
          //   position: 'top',
          //   maxItems: 100,
          //   hideOverflowingLabels: true,
          //   orientation: 'horizontal',
          //   total: {
          //       enabled: true,
          //       // formatter: undefined,
          //       offsetX: 0,
          //       offsetY: 0,
          //       style: {
          //         color: '#373d3f',
          //         fontSize: '12px',
          //         // fontFamily: undefined,
          //         fontWeight: 600
          //       }
          //     }
          // },
        },
      },
      states: {
        hover: {
            filter: {
                type: 'none',
            }
        }
    },
      dataLabels: {
        enabled: false,
        offsetX: 0,
        textAnchor: 'end',
        style: {
          fontSize: "10px",
          colors: ["#304758"],
          // colors: ["#FFFFFF"],
          fontFamily: 'IBM Plex Sans',
          fontWeight: '500',
        },
        formatter: function (val) {
          return Number(val).toLocaleString() + "%";
        },
        background: {
          enabled: true,
          foreColor: 'black',
          padding: 8,
          borderRadius: 6,
          borderWidth: 0,
          borderColor: '#fff',
          opacity: 0.3,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },        

      },
      xaxis: {
        categories: [],
        tickAmount: 10,
        crosshairs: {
      show: false // <--- HERE
    },
        labels:{
          show:false
        }
      },
      yaxis: {
        labels:{
          show:true,
          style: {
              colors: ['#000000'],
          },          
        }
      },      
      tooltip:{
        enabled:false
      }
    },
  }),
  watch: {
    selectedProperties(newVal) {
      if (newVal.length > 0) {
        this.loadData(false, true);
      } else {
        this.tableData = [];
      }
    },
  },
  created(){
    if(this.$vuetify.theme.isDark ){
      this.chartOptions.yaxis.labels.style.colors = ['#9FA2AE'] 
    } 
  },
  methods: {
    reset(){
      this.selectedProperties=[]
      this.tableData=[]
      this.filters=[]
      this.series[0].data=[]
      this.chartOptions.xaxis.categories = []
      this.$refs.myChart.refresh();
      this.$refs.moreProperties.reset()

    },
    onMorePropertiesChanged(s){
      this.selectedProperties = s
    },
    onPropertiesTypeChanged(v) {
      this.compositionType = v
      if (this.datasetId && this.packageId && this.filters.length > 0) {
        this.loadData(true, false);
      }
    },
    update(filters) {
      this.filters = filters;
      this.loadData(true, true);
    },
    loadData(refreshChart = true, refreshTable = true) {
      const columns = this.normalDatasetCompositionFields.filter((x) => x.groups.findIndex((g) => g.groupType == this.compositionType) >= 0).map((item) => item.name);
      const selectedProps = new Set(this.selectedProperties.map((item) => item.name));
      columns.forEach((element) => {
        selectedProps.add(element);
      });
      const allColumns = Array.from(selectedProps);
      const _this = this;
      this.loading = true;
      getFilteredAggregations(this.packageId, this.datasetId, allColumns, this.filters, function (res) {
        if (refreshTable) {
          _this.tableData = _this.selectedProperties.map((item) => {
            return { title: item.title, value: pruneNumber(res.result[item.name].avg, 3),id:item.id };
          });
        }
        _this.loading = false;
        if (refreshChart) {
          const chartFields = _this.normalDatasetCompositionFields.filter((x) => x.groups.findIndex((g) => g.groupType == _this.compositionType) >= 0 && x.groups.findIndex((g) => g.groupType == "ALUMINIUM") == -1);
          _this.chartOptions.xaxis.categories = chartFields.map((item) => item.title.replace("at%", "").replace("wt%", ""));
          _this.series = [
            {
              name: _this.compositionType == "COMPOSITION_WT" ? "Weight %" : "Atomic %",
              data: chartFields.map((item) => pruneNumber(res.result[item.name].avg, 2)),
            },
          ];
          _this.$refs.myChart.refresh();
        }
      });
    },
  },
};
</script>
<style >
.dataLabel{
  font-size: 10px;
  /* margin-bottom: 26px; */
  border-radius: 6px;
  font-weight: 500;
  height:16px;
  background-color: #E2EBF3;
  margin-left: -10px;
  width: 40px;
  text-align: center;
}
.theme--dark .dataLabel{
  background-color: #272A2F;

}

.theme--light .compositionBtn {
  color: #202020 !important;
  /* background-color: #d6d6d6  !important; */
  background-color: #f0f0f0   !important;
}

.theme--dark .compositionBtn{
  background-color:#00424B !important;
}

.theme--dark .textTitle{
  color: #9FA2AE !important;
}
/* .v-label{
  font-size: 10px;
} */
</style>