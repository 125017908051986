<template>
  <div :style="'width:' + width + ';height:'+height +';'" class="d-flex flex-column pb-1 gradientBox">
      <div v-if="exp" class="exp number mt-1 mr-1 mb-n3 ml-auto " style="height:10px">
        ×10<sup class="mb-2" style="display:inline-block;">{{exp}}</sup>
      </div>
      <div  class="d-flex flex-row flex-grow-1 pb-4 mt-5" >

        <div class="vertical " >{{ title}}</div>
        <v-sheet  rounded class="flex-grow-1 " max-width="12px" min-widthpha="12px" :style="'background: linear-gradient('+ gradientEnd +','+ gradientStart +')'"></v-sheet>
        <div class="d-flex flex-column mr-1" >
          <div class="flex-grow-1 numFont number" v-for="(step,index) in steps" :key="index">
           - {{step}}
          </div>
          <div class="flex-shrink-1  numFont number">
            - {{pruneNum(min)}}
          </div>
        </div>
      </div>
  </div>

</template>
<script>
import {pruneNumber} from '@/utils/number'

export default {
  name: 'GradienLegent',
  props:{
    min: {
      type: Number,
      default:0
    },
    max: {
      type: Number,
      default:0
    },
    title:{
      type: String,
      default: ''
    },
    stepCount:{
      type: Number,
      default:10
    },
    gradientStart:{
      type: String,
      default: ''
    },
    gradientEnd:{
      type: String,
      default: ''
    },
    height:{
      type: String,
      default: '300px'
    },
    width:{
      type: String,
      default: '90px'
    }
  },
  data:() => ({
    // stepSize:1,
    // exp: null

    // steps : []
  }),
  methods:{
      pruneNum(x){
        let precision = 0
        if(this.exp){
          return pruneNumber(x / Math.pow(10,this.exp), 3)
          // return x
        } else if(this.stepSize > 10 ) {
          precision = 0
        } else if(this.stepSize > 1 ) {
          precision = 1
        } else if(this.stepSize > 0.1 ) {
          precision = 2
        } else {
          precision = 3
        }
        return pruneNumber(x,precision)
      },
      // fillSteps(){
      //   let steps=[]
      //   for( var i = this.stepCount ; i>= 1; i--){
      //     steps.push(this.min + i*(this.max - this.min)/this.stepCount)
      //   }
      //   this.steps = steps
      // }
  },
  computed:{
    steps(){
        let st=[]
        for( var i = this.stepCount ; i>= 1; i--){
          st.push(this.pruneNum(this.min + i*this.stepSize))
        }
        // console.log('MIN:',this.min, 'MAX',this.max)
        return st
    },
    stepSize(){
      return (this.max - this.min)/this.stepCount
    },
    exp(){
      if(this.stepSize != 0){
        const x = Math.ceil(Math.log10(this.stepSize)) 
        if(Math.abs(x) > 2){
          return x
        } else {
          return null
        }
      } else {
        return null
      }

    }
  },
  // watch:{
  //   title(){
  //     this.fillSteps()
  //   }
  // },
  created(){
    // console.log(this.stepSize)
    // this.fillSteps()
  }
}
</script>
<style lang="scss" scoped>
.numFont{
  font-size: 12px !important;
  color: #615E83;
}

.theme--dark .numFont{
  color: #9FA2AE;
}


.vertical {
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  white-space: nowrap;
  display: block;
  bottom: 0;
  width:30px;
  font-size:14px;
  font-weight: 500;
   color: #444444;
   padding-right: 4px;
}

.theme--dark .vertical{
  color: white;
}

.exp{
  font-size: 9px;

}

.theme--light .gradientBox{
  background-color:#F6F6F6;
}
.theme--dark .gradientBox{
  background-color: #272A2F;
}
</style>