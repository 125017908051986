<template>
  <v-menu offset-y v-model="menu" :close-on-content-click="false" class="mt-10 " content-class="elevation-0 modal">
    <template v-slot:activator="{ attrs, on }">
      <v-btn color="primary" large icon v-bind="attrs" v-on="on">
        <icon id="text-fill"> </icon>
      </v-btn>
    </template>
    <div style="width:400px">
      <div class="d-flex flex-row pa-3" style="border-bottom: 1px solid rgb(236, 236, 236);">
        <div class="text-h4">Chart Style</div>
        <v-icon @click="menu=null" class="ml-auto">mdi-close</v-icon>
      </div>
      <v-row class="mx-2 my-1">
        <v-col cols="7" class="my-auto">Marker Fill Color</v-col>
        <v-col cols="5">
          <v-menu offset-y content-class="elevation-0 modal" :close-on-content-click="false">
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex flex-row colorPickerCmd " v-bind="attrs" v-on="on">
                <div class="colorSampleBox " :style="'background-color:'+fillColor"></div> 
                <v-icon class="ml-auto">mdi-chevron-down</v-icon>
              </div>
            </template>            
            <div>
              <v-color-picker dot-size="17" v-model="fillColor"  hide-inputs hide-mode-switch  swatches-max-height="158"></v-color-picker>
            </div>
          </v-menu>
        </v-col>
      </v-row>
      <v-row  class="mx-2 my-1">
        <v-col cols="7" class="my-auto">Marker Border Color</v-col>
        <v-col cols="5">
          <v-menu offset-y content-class="elevation-0 modal" :close-on-content-click="false">
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex flex-row colorPickerCmd " v-bind="attrs" v-on="on">
                <div class="colorSampleBox " :style="'background-color:'+borderColor"></div> 
                <v-icon class="ml-auto">mdi-chevron-down</v-icon>
              </div>
            </template>            
            <div>
              <v-color-picker dot-size="17" v-model="borderColor"  hide-inputs hide-mode-switch  swatches-max-height="158"></v-color-picker>
            </div>
          </v-menu>  
        </v-col>
      </v-row>
      <v-row  class="mx-2 my-1">
        <v-col cols="7"  class="my-auto">Marker Size</v-col>
        <v-col cols="5">
          <v-menu offset-y content-class="elevation-0 modal">
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex flex-row colorPickerCmd " v-bind="attrs" v-on="on">
                <div >{{ sizeItems[selectedSizeIndex] }}</div> 
                <v-icon class="ml-auto">mdi-chevron-down</v-icon>
              </div>
            </template>            
            <div>
                <v-list dense>
                  <v-list-item-group v-model="selectedSizeIndex" color="primary" >
                  <v-list-item v-for="item in sizeItems" :key="item" >{{ item }}</v-list-item>
                  </v-list-item-group>
                </v-list>
            </div>
          </v-menu>  

        </v-col>
      </v-row>
      <v-row  class="mx-2 my-1">
        <v-col cols="7">Marker Border Thickness</v-col>
        <v-col cols="5">
          <v-menu offset-y content-class="elevation-0 modal">
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex flex-row colorPickerCmd " v-bind="attrs" v-on="on">
                <div >{{ thicknessItems[selectedThicknessIndex] }}</div> 
                <v-icon class="ml-auto">mdi-chevron-down</v-icon>
              </div>
            </template>            
            <div>
                <v-list dense>
                  <v-list-item-group v-model="selectedThicknessIndex" color="primary" >
                  <v-list-item v-for="item in thicknessItems" :key="item" >{{ item }}</v-list-item>
                  </v-list-item-group>
                </v-list>
            </div>
          </v-menu>  
        </v-col>
      </v-row>
      <div class="d-flex flex-row py-3" style="border-top: 1px solid rgb(236, 236, 236);">
        <CBtn class="ml-auto" outlined width="132" @click="reset()" >Reset</CBtn>
        <CBtn class="mx-3" width="152" @click="onChanged()">Confirm</CBtn>
      </div>
    </div>
    <!-- <v-card>
      <v-card-text>
        <v-list class="pt-10">
          <v-list-item>
            <v-slider max="10" min="1" v-model="_markerSize" @change="onChange()" label="Size" thumb-color="secondary" thumb-label="always" thumb-size="23"></v-slider>
          </v-list-item>
          <v-list-item>
            <v-slider max="5" min="1" v-model="_makerThickness" @change="onChange()" label="Thickness" thumb-color="secondary" thumb-label="always" thumb-size="23"></v-slider>
          </v-list-item>
          <v-list-item> Color </v-list-item>
          <v-list-item>
            <v-color-picker dot-size="17" v-model="_markerColor" @input="onChange()"  hide-inputs hide-mode-switch  swatches-max-height="158"></v-color-picker>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card> -->
  </v-menu>
</template>

<script>
import CBtn  from '@/components/Vuetify/CBtn.vue'
export default {
    name: 'ChartSettings',
    components:{CBtn},
    data:()=>({
      menu: null,
      fillColor: null,
      borderColor: null,
      selectedSizeIndex:0,
      sizeItems:[
        2,3,4,5,6,7,8
      ],
      selectedThicknessIndex:0,
      thicknessItems:[
        0,1,2,3,4
      ]

    }),
    props:{
        markerSize: {
            type: Number,
            default: 4
        },
        makerThickness:{
            type: Number,
            default: 1
        },
        markerFillColor:{
            type: String,
            default: '#fefefe'
        },
        markerBorderColor:{
            type: String,
            default: '#fefefe'
        }
    },
    created(){
      this.reset()
    },

    methods:{
       reset(){
        this.fillColor = this.markerFillColor
        this.borderColor = this.markerBorderColor
        this.selectedSizeIndex = this.sizeItems.indexOf(this.markerSize)
        this.selectedThicknessIndex = this.thicknessItems.indexOf(this.makerThickness)

       },
        onChanged(){
          this.menu = false
            const x= {
              markerFillColor : this.fillColor,
              markerBorderColor : this.borderColor,
              markerSize : this.sizeItems[this.selectedSizeIndex],
              makerThickness : this.thicknessItems[this.selectedThicknessIndex],
            }
            this.$emit('onChanged',x)
        }
    }

    
}
</script>
<style scoped>
.modal{
  /* box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1) !important; */
  box-shadow: 0px 13px 80px 0px rgba(0, 0, 0, 0.1) !important;
  /* box-shadow: 10px 26px 80px 10px rgba(0, 0, 0, 0.5) !important; */
  background-color: white;
  font-weight: 400px;
  font-family: 'IBM Plex Sans'
}
.theme--dark .modal{
  background-color: rgb(20, 20, 20);

}

.colorPickerCmd{
  border-radius: 6px;
  padding: 4px 4px 4px 4px;
  box-shadow: 0px 0px 30px 7px rgba(0, 0, 0, 0.07);
}

.colorPickerCmd:hover{
  box-shadow: 0px 0px 30px 12px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  /* background-color:  rgb(245, 245, 245); */
}

.colorSampleBox{
  width: 24px;
  height: 24px;
  border-radius: 6px;
}
</style>

