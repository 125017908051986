<template>
  <div>
    <div id="morePropertiesHeaderId" class="d-flex flex-row morePropertiesHeader">
      <div>Property</div>
      <div class="ml-auto" style="width: 80px">Value</div>
    </div>
    <v-virtual-scroll bench="150" :height="morePropertiesHeight" item-height="45" :items="tableData">
      <template v-slot:default="{ item }">
        <MorePropertiesItem :name="item.name" :title="item.title" :id="item.id" :val="item.value" @onRemove="onRemoveSelectedProperties" ></MorePropertiesItem>
      </template>
    </v-virtual-scroll>
    <div>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-width="100" offset-y top class="body-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn id="cmdAdd" outlined block height="34px" small color="primary" v-bind="attrs" v-on="on" @click="dimdim">Add </v-btn>
        </template>
        <v-autocomplete
          id="dimdimdim"
          ref="autocomplete" 
          solo
          dense
          style="background-color: white; width: 447px"
          class="body-2"
          open-on-clear
          clearable
          :items="normalDatasetFields"
          item-text="title"
          item-value="name"
          label="title"
          persistent-hint
          hide-details="true"
          prepend-inner-icon="mdi-magnify"
          :menu-props="{closeOnClick:true}"
          @change="onChange"
        >
        </v-autocomplete>
      </v-menu>
    </div>
  </div>
</template>
<script>
import MorePropertiesItem from '@/components/Mat/MorePropertiesItem.vue';

export default {
  name: "MoreProperties",
  components: { MorePropertiesItem },
  props: {
    normalDatasetFields: {   
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },

  },
  data: () => ({
    menu:null,
    selectedProperties: []
}),
  mounted(){
  },
  computed: {
    morePropertiesHeight() {
      const el = document.getElementById("morePropertiesHeaderId");
      var diff = 170;
      if (el) {
        const r = el.getBoundingClientRect();
        diff = window.innerHeight - (r.y + r.height) - 60;
      }
      return Math.min(this.selectedProperties.length * 45, diff);
    },
  },
  methods: {
    reset(){
      this.selectedProperties=[]
    },
    dimdim(){
        setTimeout(()=>{
            // this.$refs['autocomplete'].clear()
            this.$refs['autocomplete'].focus()
            this.$refs['autocomplete'].activateMenu()

        }, 200)
        // this.$refs.autocomplete.focus()
        // this.$refs.autocomplete.isMenuActive = true
    },
    onFocus () {
      this.$refs.autocomplete.isMenuActive = true  // open item list
    },
    onChange(selectedPropertyName){
        if(selectedPropertyName)
        {

            this.menu = null
            this.selectedProperties.push(this.normalDatasetFields.find(item => item.name === selectedPropertyName))
            this.$emit('onChanged',this.selectedProperties)
        }
    },
    onRemoveSelectedProperties(id) {
      const idx = this.selectedProperties.findIndex((item) => item.id == id);
      if (idx >= 0) {
        this.selectedProperties.splice(idx, 1);
      }
      this.$emit('onChanged',this.selectedProperties)

    },
  },
};
</script>
<style scoped>

.morePropertiesHeader{
  background-color: #F0F8F9;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  height: 40px;
  width: 100%;
  margin-bottom: 4px;
}

.theme--dark .morePropertiesHeader {
  background-color: #18191A;

}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 15px;
}


::v-deep .v-list-item {
  min-height: 15px!important;
}

#cmdAdd.v-btn--outlined{
  border: 1px dashed #8E8E8E;
}

</style>