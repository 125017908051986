<template>
  <v-menu offset-y v-model="menu" :close-on-content-click="false" class="mt-10" content-class="elevation-0 modal">
    <template v-slot:activator="{ attrs, on }">
      <v-btn color="primary" large icon v-bind="attrs" v-on="on">
        <icon id="help"> </icon>
      </v-btn>
    </template>
    <div style="width: 500px">
      <div class="d-flex flex-row pa-3" style="border-bottom: 1px solid rgb(236, 236, 236)">
        <div class="text-h4">Chart Interactions</div>
        <v-icon @click="menu = null" class="ml-auto">mdi-close</v-icon>
      </div>
      <div class="ma-4">
          <ul class="help-text">
              <li><b>Zoom-In/Zoom-Out:</b> Hold Ctrl key and use mouse scroll</li>
              <li><b>Horizontal Zoom:</b> Lock X axis using <icon id="pan-vertical" size="18px"  ></icon></li>
              <li><b>Vertical Zoom:</b> Lock Y axis using <icon id="pan-horizontal" size="18px"  ></icon> </li>
              <li><b>Pan:</b> Hold Alt key and drag the chart area</li>
              <li><b>Swap X and Y:</b> Use <icon id="swap-xy" size="18px"> </icon> button</li>
              <li><b>Export Image:</b> Right click on the chart area</li>
          </ul>
        </div>
    </div>
    <!-- <v-card>
      <v-card-text>
        <v-list class="pt-10">
          <v-list-item>
            <v-slider max="10" min="1" v-model="_markerSize" @change="onChange()" label="Size" thumb-color="secondary" thumb-label="always" thumb-size="23"></v-slider>
          </v-list-item>
          <v-list-item>
            <v-slider max="5" min="1" v-model="_makerThickness" @change="onChange()" label="Thickness" thumb-color="secondary" thumb-label="always" thumb-size="23"></v-slider>
          </v-list-item>
          <v-list-item> Color </v-list-item>
          <v-list-item>
            <v-color-picker dot-size="17" v-model="_markerColor" @input="onChange()"  hide-inputs hide-mode-switch  swatches-max-height="158"></v-color-picker>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card> -->
  </v-menu>
</template>

<script>
export default {
  name: "ChartHelp",
  data: () => ({
    menu: null,
  }),
};
</script>
<style scoped>
.modal {
  /* box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1) !important; */
  box-shadow: 0px 13px 80px 0px rgba(0, 0, 0, 0.1) !important;
  /* box-shadow: 10px 26px 80px 10px rgba(0, 0, 0, 0.5) !important; */
  background-color: white;
  font-weight: 400px;
  font-family: "IBM Plex Sans";
}
.theme--dark .modal{
  background-color: rgb(20, 20, 20);

}
.help-text{
    line-height: 1.9;
}

</style>

