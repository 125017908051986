<template>
  <div style="position: relative">
    <div class="ellipse-bg"></div>
    <div class="d-flex mx-8 pt-6 flex-column">
      <div>
        <icon :id="'page-imat-' + themeMode" :size="'30px'" class="mt-n3 mr-2"></icon>
        <!-- <v-icon large color="primary" class="mb-4 mr-2">mdi-magnify-expand</v-icon> -->
        <span class="text-h1">AlloyNX PLATFORM </span>

      </div>
      <div class="mx-1">
        <v-row class="mt-1 mb-1 mx-0">
          <v-col>
            <c-select class="text-h4" :items="normalDatasets" v-model="selectedNormalDatabaseId" icon="designspace-database" item-text="name" item-value="id" label="Database" persistent-hint @change="onNormalDatasetChange()"></c-select>
          </v-col>
          <v-col class="ml-2 mr-0 px-0">
            <c-select
            class="text-h4"
              clearable
              :items="standardDatasets"
              v-model="selectedStandardDatabaseId"
              icon="standard-database"
              prepend-inner-icon="mdi-ruler-square-compass"
              item-text="name"
              item-value="id"
              label="Standard Database"
              persistent-hint
              @input="onStandardDatasetChange"
            ></c-select>
          </v-col>
        </v-row>
        <div class="d-flex flex-row roundedBox">
          <div class="chartBox d-flex flex-column justify-center align-center">
            <div v-if="!isDatabaseSelected" class="emptyState body-2 font-weight-bold">
              <img src="@/assets/img/alloy-design-empty-state.svg" />
              <p>Select Database and Benchmark Alloy to see the result</p>
            </div>
            <DesignCastingChart
              v-else
              ref="mainChart"
              :normalDatasetFields="normalDatasetFields"
              :packageId="PACKAGE_ID"
              :standardDatasetId="selectedStandardDatabaseId"
              :datasetId="selectedNormalDatabaseId"
              @onMarkerMouseOver="onChartMarkerMouseOver"
              :filters="selectedFilters"
            ></DesignCastingChart>
          </div>
          <div class="sideBox pa-5"  > 
            <div class="tabHeaderBox">
              <v-tabs v-model="selectedTab" height="30px" fixed-tabs >
                <v-tab class="font-weight-bold h5"> Stats </v-tab>
                <v-tab class="font-weight-bold h5" :disabled="normalDatasetFields.length === 0"> Filters </v-tab>
              </v-tabs>
            </div>
            <div v-if="isDatabaseSelected" style="height:94%" >
              <div v-show="selectedTab == 0">
                <CompositionChart ref="myCompositionChart" :packageId="PACKAGE_ID" :datasetId="selectedNormalDatabaseId" :normalDatasetFields="normalDatasetFields" :normalDatasetCompositionFields="normalDatasetCompositionFields" />
              </div>
              <div v-show="selectedTab == 1"  style="height:100%" >
                <div class="d-flex flex-row pt-3 pb-1">
                  <div class="text-h5 mt-2 filterTitle">Filter Composition and Properties</div>
                  <v-btn class="ml-auto" text small color="primary" @click="onResetAllFilters"> Reset All </v-btn>
                </div>
                <div class="d-flex flex-column "  id="filters" style="height:92%" >

                  <v-virtual-scroll vertical bench="150" :height="rangeSliderHeight" item-height="88" :items="normalDatasetFieldsForFilter">
                    <template v-slot:default="{ item }">
                      <RangeSlider class="px-4" v-on:onChanged="onFilterChanged" v-on:onReset="onFilterReset" :key="item.id" :id="item.id" :name="item.name" :min="item.stats.min" :max="item.stats.max" :title="item.title"></RangeSlider>
                    </template>
                  </v-virtual-scroll>
                </div>
              </div>
            </div>
            <div v-if="!isDatabaseSelected" class="body-2 mt-10 text-center px-5">Select Database and Benchmark Alloy, then Choose the axis X and Y to see the result</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFieldGroups } from "@/api/settings";
import { getDatasets } from "@/api/user/dataset";
// import { getDatasets, getData } from "@/api/user/dataset";
import { getDatasetFieldsByGroup, getDatasetFieldsByOneOfGroups } from "@/api/dataset";
import CompositionChart from "@/components/Mat/CompositionChart.vue";
import RangeSlider from "@/components/Mat/RangeSlider.vue";
import DesignCastingChart from "@/components/Mat/DesignCastingChart.vue";
import CSelect from "@/components/Vuetify/CSelect";

export default {
  name: "AlloyDesignCasting",
  components: { CSelect, CompositionChart, DesignCastingChart, RangeSlider },
  data: () => ({
    PACKAGE_ID: 2,
    //Header
    normalDatasets: [],
    standardDatasets: [],
    selectedNormalDatabaseId: null,
    selectedStandardDatabaseId: null,

    //body
    normalDatasetFields: [],
    normalDatasetCompositionFields: [],

    //Side filters
    selectedTab: 0,
    selectedFilters: {},
  }),
  created() {
    this.loadFieldGroups();
    this.loadDatasets();
  },
  computed: {
    themeMode(){
      return (this.$vuetify.theme.isDark ? 'dark' : 'light')
    },
    rangeSliderHeight(){
      return (this.selectedTab == 1 ?  document.getElementById('filters').offsetHeight : 250 )
    },
    isDatabaseSelected() {
      // return this.selectedNormalDatabaseId && this.selectedStandardDatabaseId;
      return this.selectedNormalDatabaseId ;
    },
    normalDatasetFieldsForFilter(){
      return this.normalDatasetFields.filter(item => item.stats.min != item.stats.max)
    }
  },
  methods: {
    onChartMarkerMouseOver(filters) {
      this.$refs.myCompositionChart.update(filters);
    },
    onNormalDatasetChange() {
      this.selectedFilters = Object.assign(this.selectedFilters,{})
      if(this.$refs.mainChart) {
        this.$refs.mainChart.resetChart()
        this.$refs.myCompositionChart.reset();
      }
      this.loadNormalDatasetFields();
    },
    onStandardDatasetChange() {
      // this.loadAlloyNames();
      console.log('HERE', this.selectedStandardDatabaseId)
      // if(this.$refs.mainChart) {
      //   this.$refs.mainChart.updateChart()
      // }
    },
    loadDatasets() {
      const _this = this;
      getDatasets(this.PACKAGE_ID, function (res) {
        _this.normalDatasets = res.datasets.filter((item) => item.datasetType.id === 1);
        _this.standardDatasets = res.datasets.filter((item) => item.datasetType.id === 2);
      });
    },

    // loadAlloyNames() {
    //   if (this.selectedStandardDatabaseId === undefined || this.selectedStandardDatabaseId === null) {
    //     this.standardAlloys = [];
    //     this.selectedStandardAlloyIds = [];
    //   } else {
    //     const _this = this;
    //     const alloyNameGroupId = this.fieldGroups.find((item) => item.groupType === "ID").id;
    //     getData(this.PACKAGE_ID, this.selectedStandardDatabaseId, [], [alloyNameGroupId], function (res) {
    //       _this.standardAlloys = res.result.map((item) => {
    //         var name = Object.keys(item)[0];
    //         return { id: item[name], name: item[name] };
    //       });
    //       _this.selectedStandardAlloyIds = _this.standardAlloys.map((item) => item.id);
    //     });
    //   }
    // },
    loadFieldGroups() {
      const _this = this;
      getFieldGroups(function (res) {
        _this.fieldGroups = res;
      });
    },
    loadNormalDatasetFields() {
      const _this = this;
      const groupId = [this.fieldGroups.find((item) => item.groupType === "ALLOY_DESIGN").id];
      const compositionGroups = this.fieldGroups.filter((item) => item.groupType === "COMPOSITION_AT" || item.groupType === "COMPOSITION_WT").map((f) => f.id);
      getDatasetFieldsByGroup(this.selectedNormalDatabaseId, groupId, function (res) {
        _this.normalDatasetFields = res;
      });
      getDatasetFieldsByOneOfGroups(this.selectedNormalDatabaseId, compositionGroups, function (res) {
        _this.normalDatasetCompositionFields = res;
      });
    },
    //Filter Methods
    onResetAllFilters() {
      this.$eventBus.$emit("alloyDesignResetAllFilter");
      Object.keys(this.selectedFilters).forEach((name) => {
        delete this.selectedFilters[name];
      });
      this.$refs.mainChart.updateChart();
    },
    onFilterChanged(id, name, value) {
      console.log('onFilterChanged', name, value)
      this.$set(this.selectedFilters, name, { gte: value[0], lte: value[1] });
      this.$refs.mainChart.updateChart();
    },
    onFilterReset(id, name) {
      if (this.selectedFilters[name] !== undefined) {
        delete this.selectedFilters[name];
      }
      this.$refs.mainChart.updateChart();
    },
  },
};
</script>
<style scoped>
.ellipse-bg {
  position: fixed;
  left: 50%;
  top: -50%;
  border-radius: 50%;
  background: rgba(3, 147, 168, 0.2538);
  filter: blur(150px);
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
.col {
  padding: 0;
}

.roundedBox {
  position: relative;
  border-radius: 6px;
  border: 1px solid #2b9baa;

  /* z-index:99; */
  /* border: 1px solid var(--v-primary-base); */
}
.chartBox {
  border-radius: 6px 0px 0px 6px;
  background-color: white;
  height: calc(100vh - 180px);
  /* z-index: 1; */
  width: 100%;
  border-right-color: #2b9baa;
  border-right-style:solid;
  border-right-width: 1px;

}
.theme--dark .chartBox{
  background-color: #18191A;

}
.sideBox {
  width: 500px;
  border-radius: 6px;
}

.theme--dark .sideBox{
  background-color: #1d2226 ;
}
.emptyState {
  width: 250px;
  text-align: center;
}

.tabHeaderBox {
  border-radius: 6px;
  padding: 1px 20px 8px 20px;
  background-color: white;
  /* z-index: 99; */
}
.theme--dark .tabHeaderBox{
  background-color: #1E1E1E;

}

.theme--dark .filterTitle{
  color: #C2C1D2;
}
</style>
