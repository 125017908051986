<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y class="body-2" >
    <template v-slot:activator="{ on, attrs }">
      <c-btn radius="8px" small class="btn" v-on="on" v-bind="attrs"> {{ btnText }} <v-icon>mdi-chevron-down</v-icon> </c-btn>
    </template>
    <v-autocomplete
      autofocus
      solo
      style="background-color: white; width: 407px"
      class=" body-2"
      open-on-clear
      clearable
      :items="items"
      v-model="selectedValue"
      :item-text="itemText"
      :item-value="itemValue"
      :label="title"
      persistent-hint
      hide-details="true"
      prepend-inner-icon="mdi-magnify"
      @change="onAxisChange()"
    >
    </v-autocomplete>
  </v-menu>
</template>
<script>
import CBtn from '@/components/Vuetify/CBtn.vue'

export default {
  name: 'SelectAxisBtn',
  components: {CBtn},

  props:{
    btnText:{
        type:String, 
        default: null
    },
    items:{
      type: Array,
      default: () => []
    },
    itemText:{
        type: String,
        default: null
    },
    itemValue:{
        type: String,
        default: null
    },
    value:{
      type: String,
      default: null
    },
    title:{
      type: String,
      default: null
    }

  },
  watch:{
    value(val){
      this.selectedValue = val
    }
  },
  created(){
      this.selectedValue = this.value

  },
  data:()=> ({
    menu: null,
    selectedValue:null,
  }),
  methods:{
    onAxisChange(){
      if(this.selectedValue != null){
        this.menu  = false
      }
      this.$emit('onChange', this.selectedValue)
    }
  }

}
</script>
<style lang="scss" scoped>
.v-menu__content{
    box-shadow: none;
    border: 1px solid #D2F3F5;
}
.theme--light .btn{
  background-color: #D2F3F5 !important;
  color: black !important;
}
.theme--dark .btn{
  background-color: #00424B !important;
  color: white !important;
}

</style>