<template>
  <div class="d-flex flex-column pb-1 mr-1 normalBg" :class="bgClass">

    <div class="d-flex flex-row" >

      <!-- <CTooltip :text="title">
        <div  class="mb-n4 title " :style="disabled ? 'color:rgba(203, 204, 208, 1)':''" >{{trimedTitle}}</div>
      </CTooltip> -->
    <v-tooltip :disabled="!isTitleTrimmed" top>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="mb-n4 title " :style="disabled ? 'color:rgba(203, 204, 208, 1)':''" >{{trimedTitle}}</div>
      </template>
      <span>{{title}}</span>
    </v-tooltip>
    </div >
      <v-range-slider :disabled="disabled" :thumb-color="changed? 'primary' : 'rgba(234, 234, 234, 1)'" :color="changed? 'primary' : 'rgba(234, 234, 234, 1)'" track-color="rgba(210, 243, 245, 1)" tick-size="1" dense  hide-details  @change="onChange()" v-model="value" :min="min" :step="step" :max="max" >
      </v-range-slider>
    <div class="mt-n1 mb-1 d-flex flex-row ">
      <CTextField border="1px" :disabled="disabled" height="27px" hide-details validate-on-blur class="number" :changed="changed"  @change="onChangeMin" :rules="[(v)=>(!isNaN(v) && !isNaN(parseFloat(v)) && Number(v) >= min && Number(v) <= max )]"   :value="pruneNum(value[0])"></CTextField>
      <v-icon  small :color="disabled? 'grey lighten-2 ':'grey darken-1'">mdi-minus</v-icon>
      <CTextField border="1px" :disabled="disabled" height="27px" hide-details validate-on-blur class="number " :changed="changed" @change="onChangeMax" :rules="[(v)=>(!isNaN(v) && !isNaN(parseFloat(v)) && Number(v) >= min && Number(v) <= max )]"  :value="pruneNum(value[1])"></CTextField>
      <v-icon class="ml-1" :disabled="!changed" :color="disabled?'rgba(203, 204, 208, 1)':'primary'" small @click="reset()">mdi-reload</v-icon>
      <!-- <v-text-field :disabled="disabled" outlined dense hide-details validate-on-blur @change="onChangeMax" :rules="[(v)=>(!isNaN(v) && !isNaN(parseFloat(v)) && Number(v) >= min  && Number(v) <= max )]" class="shrink body-2 number"   :value="pruneNum(value[1],4)"></v-text-field> -->
    </div>
  </div>
</template>
<script>
import {smartPruneNumber} from '@/utils/number'
// import {pruneNumber,smartPruneNumber} from '@/utils/number'
import CTextField from '@/components/Vuetify/CTextField.vue'
// import CTooltip from '@/components/Vuetify/CTooltip.vue'

export default {
  name: 'RangeSlider',
  components: {CTextField},
  props:{
    id:{
      type: Number,
      default: 0
    },
    name:{
      type: String,
      default: ""
    },
    min:{
      type: Number,
      default: 0
    },
    max:{
      type: Number,
      default: 0
    },
    title:{
      type: String,
      default: ''
    }
  },
  data: ()=>({
    step:0,
    value:[],
    changed:false,
    trimedTitle: '',
    isTitleTrimmed: false
  }),
  computed:{
    bgClass(){
      // if(this.min == this.max){
      //   return 'grey lighten-2'
      // } else if (this.changed){
      //   return 'changedBg'
      // } else {
      //   return ''
      // }
      if (this.changed){
        return 'changedBg'
      } else {
        return ''
      }
    },
    disabled(){
      return (this.min == this.max)
    }

  },
  mounted(){
    this.$eventBus.$on('alloyDesignResetAllFilter',()=>{ this.onResetAllCallback()})
  },
  created(){
    this.step = (this.max - this.min ) / 500
    this.value = [this.min, this.max]
    const maxLength = 45
    this.trimedTitle = this.title
    if(this.title.length > maxLength){
      this.isTitleTrimmed = true;
      this.trimedTitle = this.title.slice(0,maxLength-1) + "…"
    }
  },
  methods:{
    onResetAllCallback(){
      this.reset(false)
    },
    onChangeMax(v){
      if(Number(v) >= this.min && Number(v) <= this.max){
        this.$set(this.value,1,Number(v))
        this.onChange()
      }
    },
    onChangeMin(v){
      if(Number(v) >= this.min && Number(v) <= this.max){

      this.$set(this.value,0,Number(v))
      this.onChange()
      }
    },
    onChange(){

      if (this.min === this.value[0] && this.max === this.value[1]){
        this.changed = false
        this.$emit('onReset',this.id, this.name)
      } else {
        this.changed = true
        this.$emit('onChanged',this.id, this.name, this.value)
      }
    },
    pruneNum(num){
      return smartPruneNumber(num)
    },
    reset(emitOnReset=true){
      if(this.changed === true){
        this.changed = false
        // this.value = [ this.min, this.max ]
        this.$set(this.value,0,this.min)
        this.$set(this.value,1,this.max)
        if(emitOnReset){
          this.$emit('onReset', this.id, this.name)
        }
      }
    }
  }
}
</script>
<style scoped >
.normalBg{
  box-shadow: 0px 4px 4px 0px rgba(3, 147, 168, 0.08);
  background-color: white;
  border-radius: 6px;
}
.changedBg{
  background-color: rgba(246, 246, 246, 1);
}

.theme--dark .normalBg{
  background-color: #18191A;
}

.theme--dark .changedBg{
  background-color: #272A2F;
}

::v-deep .v-slider__thumb{
  width: 8px !important;
  height: 8px !important;
}

::v-deep .v-slider__thumb::before{
  left: -5px !important;
  top: -5px !important;
  width:18px;
  height:18px;
  transform: scale(0.1);
}
::v-deep .v-slider__track-background{
  background: #C6F6F9 !important;
  /* background: red !important; */
}
.changedBg ::v-deep .v-slider__track-fill{
  background: var(--v-primary-base) !important;
  /* background: red !important; */
}

.theme--dark ::v-deep .v-slider__track-fill:not(.primary){
  background: #252525 !important;
}

::v-deep .v-input__control{
  border-color: red !important;
}

.title{
  font-family: 'IBM plex sans' !important;
  font-size: 12px!important;
  font-weight: 400;
}

/* ::v-deep .normalText{
  color: rgba(107, 117, 136, 1) !important;
}
::v-deep .changedText{
  color: black !important;
} */
</style>